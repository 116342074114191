import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import DateSvg from '../../../static/svg/dateSvg';

const LookingAhead2023Banner = () => {
  return (
    <div
      className="banner banner-style-6"
      style={{
        backgroundImage: "url('/images/lookingahead2023/bg.jpg')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <div className="banner-content -welcome">
              <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                <h1 className="title">Looking Ahead 2023</h1>
                <h3 className="subtitle">
                  SME Report by <span>banco</span>
                </h3>
                <p className="desc">
                  While many countries are gradually regaining a sense of normalcy, the business
                  landscape has been forever changed by nearly three years of living with the
                  pandemic. Our curated report details the business, technology and global trends
                  for SMEs to navigate the year ahead in 2023.
                </p>
                <div className="stamp-wrap">
                  <img src="/images/lookingahead2023/stamp.png" alt="" />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LookingAhead2023Banner;
